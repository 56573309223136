<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('Hon Fung Hong Kong') }}</h1>
          <div v-if="langcode == 'en'" style="text-align: left;">
            We provides professional installation service, shock absorber tuning, general automobile repair, modification, maintenance, car inspection and other services. We can order Japanese Honda parts and major brands of tuning parts for our customers. 
          </div>
          <div v-else style="text-align: left;">
            瀚峰香港有限公司提供專業安裝服務、避震調教、一般汽車維修、改裝、保養、驗車等服務。我們可為客戶訂購日本本田原廠零件及各大改裝品牌零件。
          </div>
          <br/>
          <ul class="icons">
            <li>{{ $t('Address') }}: {{ $t('G/F 330 PAK SHA TSUEN YUEN LONG NT')}}</li>
            <li>{{ $t('Tel') }}: 852-5599 9919</li>
          </ul>
          <a href="#" @click="viewAboutUs" style="margin-right: 10px">
            {{ $t('Company Overview')}}
          </a>
          <a href="#" @click="viewService">
            {{ $t('Our Service')}}
          </a>
        </header>

        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />

        <!-- Footer -->
          <footer id="footer">
            <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
            <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
            <ul class="copyright">
              <li>&copy;  HON FUNG HONG KONG LIMITED.</li>
            </ul>
          </footer>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en' ">Company Overview</h1>
          <h1 v-else>公司概要</h1>
          <div class="description" v-if="langcode == 'en' ">
            Our store specializes in Japan Honda original parts, major Japanese tuning brands and rims!
            Specializing in Honda S2000 repair.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            本店專營日本Honda原廠零件，各大日本改裝品牌及輪圈
            專營Honda S2000維修。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
    <div class="our-service">
      <div id="main-3">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en' ">Our Service</h1>
          <h1 v-else>我們的服務</h1>
          <div class="description" v-if="langcode == 'en' ">
            We provide professional installation service, shock absorber tuning, general automobile repair, modification, maintenance and car inspection services. We can order Japanese Honda parts and major brands of tuning parts for our customers.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            提供專業安裝服務、避震調教、一般汽車維修、改裝、保養、驗車等服務。
            我們可為客戶訂購日本本田原廠零件及各大改裝品牌零件。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/03.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    //this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, welcome to Hon Fung Hong Kong. How can I help you? ',
          disableInput: false,
        }
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'answer': "我們的營業時間是星期一至六 11:00 - 20:00。",
        },
        {
          'question': 'service',
          'answer': '提供專業安裝服務、避震調教、一般汽車維修、改裝、保養、驗車等服務。我們可為客戶訂購日本本田原廠零件及各大改裝品牌零件。',
        },
        {
          'question': 'price',
          'answer': "歡迎你向我們私下查詢報價： whatsapp 5599 9928 Alex/ 5599 9919",
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods:{
    viewAboutUs(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    viewService(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.our-service');
      about.style.display = 'block';
    },
    backIndex(){
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const service = document.querySelector('.our-service');
      service.style.display = 'none';
    },
    async loadConfig(){
      return new Promise(async(resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
           'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if(status !== 200 && status !== 401){
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if(status === 401){
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent(){
      try{
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      const question = userAction['value'];
      let answer = this.replyTexts.find(function(item){
        return item.question === question
      });

      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      if(answer){
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      }else{
        const reply = {
          agent: 'bot',
          type: 'button',
          text: 'These answer maybe able to help you:',
          disableInput: false,
          options: [
            {
              text: '你們的營業時間是？',
              value: 'business_hour',
              action: 'postback' // Request to API
            },
            {
              text: '你們的服務範圍是？',
              value: 'service',
              action: 'postback' // Request to API
            },
            {
              text: '你們的價格範圍是？',
              value: 'price',
              action: 'postback' // Request to API
            },
          ],
        }
        this.data.push(reply);
      }
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
 }),
}
</script>

<style lang="scss">
h1{
  text-align: left;
}
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board{
  padding-bottom: 30px;
}
#viewer .slide .image{
  @media screen and (min-width: 1300px){
    background-size: 100%;
  }
}
.about-us, .our-service{
  display: none;
}
.partner{
  margin-top: 50px;
  .logos{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  img{
    display: block;
    width: 100%;
    max-width: 100px;
    margin-right: 10px;
  }
}
.description{
  text-align: left;
  margin-bottom: 20px;
}
</style>
